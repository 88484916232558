import React, { useEffect, useState, useRef } from "react"
import {
  Card,
  Button,
  Table,
  Modal,
  message,
  Select,
  Image,
  Checkbox,
  Radio,
  Tooltip,
} from "antd"
import { useHistory } from "react-router-dom"
import {
  QuestionCircleOutlined,
  LeftOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons"
import styles from "./Index.module.css"
import * as ShopmanageDevice from "../../services/storemanage"
import PubInput from "../../../components/PubInput"
import dayjs from "dayjs"
import NoImgSrc from "../../../images/noImgSrc.png"
import Decimal from "decimal.js"
const { Option } = Select
const projectId = localStorage.getItem("uid")

function Index(props) {
  let history = useHistory()
  let WQ
  let SD
  if (props?.location?.search) {
    if (props?.location?.search?.split("?")[1]) {
      let NewStr = props?.location?.search?.split("?")[1].split("&")
      if (NewStr[0]) {
        WQ = NewStr[0].split("=")[1]
      }
      if (NewStr[1]) {
        SD = NewStr[1].split("=")[1]
      }
    }
    // if (props?.location?.search?.split("=")) {
    //   WQ = props?.location?.search?.split("=")[1]
    // }
  }
  const [WinHeight, setWinHeight] = useState() // 动态获取浏览器高度
  const [modalLoading, setModalLoading] = useState(false) // 弹窗Loading

  // 表格数据
  const [tableList, setTableList] = useState([])
  const [total, setTotal] = useState(0) // 表格总数
  const [tableLoading, setTableLoading] = useState(false) // 表格Loading
  const [PullList, setPullList] = useState([]) // 门店下拉
  const [DeviceList, setDeviceList] = useState([]) // 设备下拉

  // 学习图片下发
  const [imgList, setImgList] = useState([]) // 图片列表
  const [imgInfo, setImgInfo] = useState({})
  const [shopName, setShopName] = useState(undefined) // 所属门店：
  const [imgIndex, setImgIndex] = useState(0) // 图片下标
  const listItemsRef = useRef([])
  const [visible, setVisible] = useState(false)
  const [visibleSec, setVisibleSec] = useState(false)
  const [deviceSns, setDeviceSns] = useState([]) // 学习图片下发
  const [shareType, setShareType] = useState(undefined) // 下发方式
  const [weightImg, setWeightImg] = useState(undefined) // 称重记录图片
  const [checkImgNum, setCheckImgNum] = useState([]) // 记录勾选图片数量数组
  //
  const [warnType, setWarnType] = useState(undefined) // 识别预警类型：1：总识别率，2：首位识别率
  const [NewDeviceWarn, setNewDeviceWarn] = useState(undefined)

  // --------------
  const [ParamsInfo, setParamsInfo] = useState({
    pageNum: 1,
    pageSize: 20,
    shopId: undefined, // 门店
    code: undefined, // 商品名称或PLU或条码
    status: undefined, // 商品状态
    warningQuery: WQ ? WQ : undefined, // 总识别率
    recentlyWeighedSku: WQ ? true : false, // 近7天称重商品
  })
  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  })
  // const contentStyle = {
  //   margin: 0,
  //   height: "100%",
  //   color: "#fff",
  //   lineHeight: "160px",
  //   textAlign: "center",
  //   background: "#364d79",
  // }
  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    }
  }, [ParamsInfo])

  // -----------------

  /**
   * 初始化
   */
  useEffect(() => {
    // 门店列表
    getPullList()
    getSetWarning()
  }, [])

  /**
   * 动态获取高度
   */
  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700))
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"))
    }
  }, [sessionStorage.getItem("INNERHEIGHT")])

  /**
   * 获取项目的识别预警规则
   */
  const getSetWarning = async () => {
    try {
      const res = await ShopmanageDevice.SetWarning()
      if (!res) {
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setWarnType(data?.warningField || undefined)
        setNewDeviceWarn(
          data?.deviceWarning
            ? new Decimal(data?.deviceWarning).mul(100).toFixed(2)
            : data?.warningField == 1
            ? 95
            : 70 || data?.warningField == 1
            ? 95
            : 70
        )
      }
      console.log(data)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询表格数据
   */
  const getTableList = async (id) => {
    try {
      const { ParamsInfoRef } = refInfo?.current
      const params = {
        ...ParamsInfoRef,
        shopId: SD
          ? Number(SD)
          : id
          ? Number(id)
          : ParamsInfoRef?.shopId
          ? Number(ParamsInfoRef?.shopId)
          : "",
        recentlyWeighedSku: ParamsInfoRef?.recentlyWeighedSku ? 1 : "0",
      }
      setTableLoading(true)
      const res = await ShopmanageDevice.shopGoodsList(params)
      if (!res) {
        setTableLoading(false)
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        setTableLoading(false)
        return
      }
      if (data && data.list) {
        setTableList(data.list)
        setTotal(data.total)
      } else {
        setTableList([])
        setTotal(0)
      }
      setTableLoading(false)
    } catch (error) {
      setTableLoading(false)
      console.log(error)
    }
  }

  /**
   * 门店下拉列表
   */
  const getPullList = async () => {
    try {
      const params = { pageNum: 1, pageSize: 9999999 }
      const { code, data, msg } = await ShopmanageDevice.New_Pull_list(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setPullList(data.list || [])
        if (data.list.length > 0) {
          setParamsInfo({
            ...ParamsInfo,
            shopId: SD ? Number(SD) : data.list[0].shopId,
          })
          getTableList(data.list[0].shopId)
          setShopName(data.list[0].shopName)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 设备列表
   */
  const getDevicePullList = async () => {
    const { ParamsInfoRef } = refInfo?.current
    try {
      const params = {
        pageNum: 1,
        pageSize: 9999999,
        shopId: ParamsInfoRef?.shopId,
      }
      const { code, data, msg } = await ShopmanageDevice.devicePullList(params)
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data) {
        setDeviceList(data?.list || [])
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 获取员工打称记录
   */
  const getGetGoodsWeighDetail = async (deviceSn, skuCode) => {
    const { ParamsInfoRef } = refInfo?.current
    try {
      const params = {
        pageNum: 1,
        pageSize: 9999,
        deviceSn,
        startDate: dayjs(
          dayjs(dayjs().subtract(7, "day")).format("YYYY-MM-DD") + " 00:00:00"
        ).valueOf(),
        endDate: dayjs(dayjs().format("YYYY-MM-DD") + " 23:59:59").valueOf(),
        shopId: ParamsInfoRef?.shopId,
        skuCode,
        projectId,
      }
      const res = await ShopmanageDevice.GetGoodsWeighDetail(params)
      if (!res) {
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        return
      }
      if (data.rows) {
        const updatedImgList = data.rows.map((item) => ({
          ...item,
          isIssued: false,
        }))
        setImgList(updatedImgList || [])
      }

      // {
      //   url: "http://img2.baidu.com/it/u=120844242,1499401620&fm=253&app=138&f=JPEG?w=800&h=1422",
      //   isIssued: false,
      //   id: 1,
      // },
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * 查询
   */
  const onSearch = () => {
    setParamsInfo({
      ...ParamsInfo,
      pageNum: 1,
    })
    setTimeout(getTableList, 0)
  }
  /**
   * 重置
   */
  const onReset = () => {
    setParamsInfo({
      ...ParamsInfo,
      pageNum: 1,
      pageSize: 20,
      // shopId: undefined, // 门店名称
      code: undefined, // 商品名称或PLU或条码
      status: undefined, // 商品状态
      // warningQuery: undefined, // 总识别率
      recentlyWeighedSku: false, // 近7天称重商品
    })
    setTimeout(getTableList, 0)
  }
  /**
   * 学习图片下发确认按钮
   */
  const onNextOkSec = async () => {
    try {
      const imageUrls = imgList
        .filter((item) => item.isIssued)
        .map((item) => item.fileName)
      const params = {
        deviceSns, // 学习图片下发
        shareType, // 下发方式
        imageUrls,
        skuCode: imgInfo.skuCode,
        skuName: imgInfo.skuName,
      }
      console.log(params)
      if (deviceSns.length == 0) {
        message.error("请选择学习图片下发设备")
        return
      }
      if (!shareType) {
        message.error("请选择下发方式")
        return
      }
      if (imageUrls.length < 5) {
        message.error("请选择下发图片")
        return
      }
      setModalLoading(true)
      const res = await ShopmanageDevice.LearnTaskShare(params)
      if (!res) {
        setModalLoading(false)
        return
      }
      const { code, data, msg } = res
      if (code != 200) {
        message.error(msg)
        setModalLoading(false)
        return
      }
      if (data) {
        message.success("学习图片下发成功")
        setVisible(false)
        setVisibleSec(false)
        setModalLoading(false)
        setCheckImgNum([])
        setParamsInfo({
          ...ParamsInfo,
          pageNum: 1,
        })
        setTimeout(getTableList, 0)
      }
    } catch (error) {
      setModalLoading(false)
      console.log(error)
    }
  }

  /**
   * 右侧图片列表勾选功能
   */
  const handleCheckboxChange = (id) => {
    // 创建一个新的数组副本
    const newImgList = imgList.map((v) => ({
      ...v,
      isIssued: v.id === id ? !v.isIssued : v.isIssued,
    }))
    // 更新状态
    setImgList(newImgList)

    //记录勾选图片数量数组
    const trueIsIssuedIds = newImgList
      .filter((item) => item.isIssued)
      .map((item) => item.id)
    setCheckImgNum(trueIsIssuedIds)
  }
  /**
   * 分页
   */
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: total,
      onChange: (current) => {
        setParamsInfo({
          ...ParamsInfo,
          pageNum: current,
        })
        setTimeout(getTableList, 0)
      },
    }
    return paginationProps
  }
  /**
   * 列表展示字段
   */
  const columns = [
    {
      title: "商品名称",
      dataIndex: "skuName",
      key: "skuName",
      ellipsis: { showTitle: true },
      width: 200,
    },
    {
      title: "商品图片",
      dataIndex: "imageUrl",
      key: "imageUrl",
      ellipsis: { showTitle: true },
      width: 200,
      render: (info) => {
        return info ? (
          <Image
            width={60}
            height={60}
            style={{ objectFit: "cover" }}
            src={info}
          />
        ) : (
          <Image
            width={60}
            height={60}
            style={{ objectFit: "cover" }}
            src={NoImgSrc}
          />
        )
      },
    },
    {
      title: "商品PLU",
      dataIndex: "skuCode",
      ellipsis: { showTitle: true },
      key: "skuCode",
      width: 150,
    },
    {
      title: "称重次数",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天称重次数">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "count",
      key: "count",
      width: 150,
      ellipsis: { showTitle: true },
      render: (info) => {
        return <div>{!info || info == 0 ? "0" : info}</div>
      },
    },
    {
      title: "总识别率",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天识别率">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "aiRate",
      key: "aiRate",
      width: 150,
      ellipsis: { showTitle: true },
      render: (info) => {
        return (
          <div>
            {!info || info == 0 ? (
              "0%"
            ) : (
              <span
                style={{
                  color:
                    info * 100 < NewDeviceWarn && warnType == 1 ? "red" : "",
                }}
              >
                {(info * 100).toFixed(2) + "%"}
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: "首位识别率",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天首位识别率">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "top1",
      ellipsis: { showTitle: true },
      key: "top1",
      width: 150,
      render: (info) => {
        return (
          <div>
            {!info || info == 0 ? (
              "0%"
            ) : (
              <span
                style={{
                  color:
                    info * 100 < NewDeviceWarn && warnType == 2 ? "red" : "",
                }}
              >
                {(info * 100).toFixed(2) + "%"}
              </span>
            )}
          </div>
        )
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      ellipsis: { showTitle: true },
      key: "status",
      width: 100,
      render: (info) => {
        return info == 1 ? "锁定" : info == 2 ? "上架" : info == 3 ? "下架" : ""
      },
    },
    {
      title: "最近称重时间",
      dataIndex: "lastSellTime",
      ellipsis: { showTitle: true },
      key: "lastSellTime",
      width: 200,
    },
    {
      title: "操作",
      dataIndex: "",
      fixed: "right",
      ellipsis: { showTitle: true },
      key: "",
      width: 200,
      render: (info, rowInfo) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                console.log(rowInfo)
                setImgIndex(0)
                setDeviceSns([])
                setShareType(undefined)
                setWeightImg(undefined)
                setCheckImgNum([])
                setVisible(true)
                setModalLoading(false)
                // 设备列表
                getDevicePullList()
                // 存储该条信息
                setImgInfo(rowInfo)
                // 获取员工打称记录（获取图片展示）
                getGetGoodsWeighDetail("", rowInfo?.skuCode)
              }}
            >
              下发学习图片
            </Button>
            <Button
              type="link"
              onClick={() => {
                history.push(
                  `/admin/goodsmanage/studyimgrecords?shopId=${ParamsInfo?.shopId}&skuCode=${rowInfo?.skuCode}`
                )
              }}
            >
              图片下发记录
            </Button>
          </>
        )
      },
    },
  ]
  return (
    <div>
      <Card>
        <div className={styles.shop_goods_info_head}>
          <div className={styles.shop_goods_info_head_search}>
            <div>
              门店：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                style={{ width: 200 }}
                placeholder="请选择所属门店"
                value={ParamsInfo.shopId || undefined}
                onChange={(e, option) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    pageNum: 1,
                    shopId: e || undefined,
                  })
                  setTimeout(getTableList, 0)
                  setShopName(option.children || undefined)
                }}
              >
                {PullList.map((v) => (
                  <Option value={v.shopId} key={v.shopId}>
                    {v.shopName}
                  </Option>
                ))}
              </Select>
            </div>
            <div>
              商品名称/PLU：
              <PubInput
                onChange={(val) => {
                  setParamsInfo({ ...ParamsInfo, code: val })
                }}
                placeholder="商品名称或PLU"
                value={ParamsInfo.code}
                allowClear={true}
              />
            </div>
            <div>
              商品状态：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择商品状态"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.status}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    status: e,
                  })
                }}
              >
                <Option value={1} key={1}>
                  锁定
                </Option>
                <Option value={2} key={2}>
                  上架
                </Option>
                <Option value={3} key={3}>
                  下架
                </Option>
              </Select>
            </div>
            <div>
              {warnType == 1 ? "总识别率：" : "首位识别率："}
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择总识别率"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo.warningQuery}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({
                    ...ParamsInfo,
                    warningQuery: e,
                  })
                }}
              >
                <Option value={"1"} key={"1"}>
                  高于预警值
                </Option>
                <Option value={"2"} key={"2"}>
                  低于预警值
                </Option>
              </Select>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip placement="top" title="每天凌晨更新相关数据">
                近7天称重商品：
              </Tooltip>

              <Checkbox
                checked={ParamsInfo.recentlyWeighedSku}
                onChange={(e) => {
                  console.log(e.target.checked)
                  setParamsInfo({
                    ...ParamsInfo,
                    recentlyWeighedSku: e.target.checked,
                  })
                }}
              />
            </div>
          </div>
          <div className={styles.shop_goods_info_head_btn}>
            <Button type="primary" onClick={onSearch}>
              查询
            </Button>
            <Button onClick={onReset}>重置</Button>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: "16px" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            paddingBottom: "20px",
          }}
        >
          <Button
            type="primary"
            onClick={() => {
              history.push(
                `/admin/goodsmanage/studyimgrecords?shopId=${ParamsInfo?.shopId}&skuCode=''`
              )
            }}
          >
            图片下发记录
          </Button>
        </div>
        <Table
          columns={columns}
          pagination={onTableChange()}
          dataSource={tableList}
          rowKey="skuCode"
          loading={tableLoading}
          scroll={{ x: "max-content", y: `${WinHeight - 420}px` }}
        />
      </Card>

      {/* 学习图片下发 */}
      <Modal
        title="学习图片下发"
        keyboard
        open={visible}
        onCancel={() => {
          setVisible(false)
        }}
        width={"60%"}
        style={{ zIndex: 999, overflow: "visible !important" }}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setVisible(false)
            }}
          >
            取消
          </Button>,
          <Button
            disabled={
              checkImgNum.length >= 5 && checkImgNum.length <= 50 ? false : true
            }
            key="submit"
            type="primary"
            onClick={() => setVisibleSec(true)}
          >
            确认下发
          </Button>,
        ]}
      >
        <div className={styles.pageModal}>
          {/* 左侧 */}
          <div className={styles.pageModalChild}>
            {/* 顶部内容 */}
            <div className={styles.pageModalChildHead}>
              <div className={styles.pageModalChildHeadDiv}>
                <span className={styles.pageModalChildHeadSpan}>
                  商品名称：
                </span>
                <div>{imgInfo?.skuName}</div>
              </div>
              <div className={styles.pageModalChildHeadDiv}>
                <span className={styles.pageModalChildHeadSpan}>PLU：</span>
                <div>{imgInfo?.skuCode}</div>
              </div>
              <div className={styles.pageModalChildHeadDiv}>
                <span className={styles.pageModalChildHeadSpan}>
                  所属门店：
                </span>
                <div>{shopName}</div>
              </div>
            </div>
            {/* 预览图展示 */}
            <div className={styles.pageModalChildImgBody}>
              <div className={styles.pageModalChildImgBodyDiv}>
                {imgList.length == 0 ? (
                  <div className={styles.noData}>
                    <ExclamationCircleOutlined style={{ fontSize: 40 }} />
                    <div>暂无图片</div>
                  </div>
                ) : (
                  <Image
                    src={imgList[imgIndex]?.fileName}
                    width={"100%"}
                    height={"100%"}
                    style={{ objectFit: "contain" }}
                  />
                )}

                {imgIndex == 0 ? null : imgList.length == 0 ? null : (
                  <div
                    className={styles.pageModalChildImgBodyDivLeBtn}
                    onClick={() => {
                      setImgIndex((pre) => {
                        return pre * 1 - 1
                      })
                      let index = imgIndex * 1 - 1
                      if (listItemsRef.current[index]) {
                        listItemsRef.current[index].scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                          inline: "nearest",
                        })
                      }
                    }}
                  >
                    <LeftOutlined style={{ color: "#2eb998" }} />
                  </div>
                )}
                {imgList.length == 0 ? null : imgIndex ==
                  imgList.length - 1 ? null : (
                  <div
                    className={styles.pageModalChildImgBodyDivRiBtn}
                    onClick={() => {
                      setImgIndex((pre) => {
                        return pre * 1 + 1
                      })
                      let index = imgIndex * 1 + 1
                      if (listItemsRef.current[index]) {
                        listItemsRef.current[index].scrollIntoView({
                          behavior: "smooth",
                          block: "nearest",
                          inline: "nearest",
                        })
                      }
                    }}
                  >
                    <RightOutlined style={{ color: "#2eb998" }} />
                  </div>
                )}
              </div>
            </div>
            {/* 底部内容 */}
            <div className={styles.pageModalChildHead}>
              <div>
                设备下发：
                <Select
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder="请选择设备"
                  style={{ width: 180 }}
                  allowClear
                  value={deviceSns}
                  showSearch
                  maxTagCount={1}
                  filterOption={(input, option) => {
                    const deviceNo = option.children.props.children[0];
                    const cdKeyCode = option.children.props.children[1];
                    return (
                      (deviceNo && deviceNo.toLowerCase().includes(input.toLowerCase())) ||
                      (cdKeyCode && cdKeyCode.toLowerCase().includes(input.toLowerCase()))
                    );
                  }}
                  onChange={(e) => {
                    setDeviceSns(e)
                  }}
                >
                  {DeviceList.map((v) => (
                    <Option
                      value={v.deviceSn}
                      key={v.deviceSn}
                      disabled={v.online === 1 ? false : true}
                      title={`${v.scaleNo ? v.scaleNo : v.scaleNo}${v.cdKeyCode ? "秤 - " + v.cdKeyCode : ""}`}
                    >
                      <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                        {v.scaleNo ? v.scaleNo : v.scaleNo}
                        {v.cdKeyCode ? "秤 - " + v.cdKeyCode : ""}
                      </div>
                    </Option>
                  ))}
                </Select>
              </div>
              <div className={styles.pageModalChildHeadDiv}>
                下发方式：
                <Radio.Group
                  onChange={(e) => {
                    setShareType(e.target.value)
                  }}
                  value={shareType}
                >
                  <Radio value={"0"}>全量下发</Radio>
                  <Radio value={"1"}>增量下发</Radio>
                </Radio.Group>
              </div>
            </div>
          </div>
          {/* 右侧 */}
          <div className={styles.pageModalChild}>
            {/* 顶部内容 */}
            <div className={styles.pageModalRightHead}>
              <div>
                称重记录图片：
                <Select
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder="请选择设备"
                  style={{ width: 200 }}
                  allowClear
                  value={weightImg}
                  showSearch
                  filterOption={(i, o) =>
                    o.props?.children[0]?.includes(i) ||
                    o.props?.children[1]?.includes(i)
                  }
                  onChange={(e) => {
                    setWeightImg(e)
                    getGetGoodsWeighDetail(e, imgInfo.skuCode)
                  }}
                >
                  {DeviceList.map((v) => (
                    <Option value={v.deviceSn} key={v.deviceSn}>
                      {v.scaleNo ? v.scaleNo : v.scaleNo}
                      {v.cdKeyCode ? "秤 - " + v.cdKeyCode : ""}
                    </Option>
                  ))}
                </Select>
              </div>
              <div className={styles.pageModalRightHeadNum}>
                已选择 {checkImgNum.length}/{imgList.length}
              </div>
            </div>
            {/* 图片列表展示 */}
            <div className={styles.pageModalChildImgBody}>
              <div className={styles.pageModalChildImgBodyDivNew}>
                {imgList.length == 0 ? (
                  <div className={styles.noData}>
                    <ExclamationCircleOutlined style={{ fontSize: 40 }} />
                    <div>暂无图片</div>
                  </div>
                ) : (
                  imgList.map((v, i) => (
                    <div
                      key={i}
                      className={styles.pageModalChildImgBodyDivNewItem}
                      ref={(el) => (listItemsRef.current[i] = el)}
                      style={{
                        border: imgIndex == i ? "2px solid #2eb998" : "",
                      }}
                      onClick={() => {
                        setImgIndex(i)
                      }}
                    >
                      <Image
                        preview={false}
                        src={v.fileName}
                        width={"100%"}
                        height={"100%"}
                        style={{ objectFit: "cover" }}
                      />
                      <Checkbox
                        style={{ transform: "scale(1.5)" }}
                        checked={v.isIssued}
                        onChange={(e) => handleCheckboxChange(v.id)}
                        className={styles.pageModalCheck}
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
            {/* 底部内容 */}
            <div className={styles.pageModalChildFoot}>
              图片来源:商品近7天称重记录，下发数量支持5-50张
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="系统提示"
        open={visibleSec}
        keyboard={!modalLoading}
        maskClosable={!modalLoading}
        onCancel={() => {
          if (modalLoading) {
            message.warning("正在下发，请稍等……")
            return
          }
          setVisibleSec(false)
        }}
        footer={[
          <Button
            disabled={modalLoading}
            key="back"
            onClick={() => {
              if (modalLoading) {
                message.warning("正在下发，请稍等……")
                return
              }
              setVisibleSec(false)
            }}
          >
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onNextOkSec}
            loading={modalLoading}
          >
            确认下发
          </Button>,
        ]}
      >
        <QuestionCircleOutlined
          style={{ color: "#E6A23C", marginRight: "10px" }}
        />
        是否将已选择的内容作为学习图片下发至选择的设备？
      </Modal>
    </div>
  )
}

export default Index
