import React, { useEffect, useState, useRef } from "react";
import {
  Select,
  Button,
  Table,
  Modal,
  message,
  Form,
  Card,
  Cascader,
  Tooltip,
  TreeSelect,
  Input,
  Space,
} from "antd";
import * as DeviceServices from "../../services/device_manage";
import {
  QuestionCircleOutlined,
  DownOutlined,
  QuestionCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import PubInput from "../../../components/PubInput";
import styles from "./Index.module.css";
import Decimal from "decimal.js";
import { Dropdown } from "antd/lib";
import { configs } from "../../../config/api.config.js";
import { useLocation } from "react-router-dom";
const { Option } = Select;
const { confirm } = Modal;
/**
 * 设备列表
 */
function Index(props) {
  const windowRef = useRef(null);
  let JumpInfo;
  if (props?.location?.search) {
    if (props?.location?.search?.split("=")) {
      JumpInfo = props?.location?.search?.split("=")[1];
    }
  }
  const [WinHeight, setWinHeight] = useState(); // 动态获取浏览器高度

  //
  const [EditDeviceOpen, setEditDeviceOpen] = useState(false); // 编辑设备打开Modal
  const [FormValuie, setFormValuie] = useState({
    scaleNo: undefined,
    id: undefined,
    shopId: undefined,
    regionId: undefined,
  }); // 设备门店时的回显
  //监控设置
  const [form] = Form.useForm();
  const history = useHistory();
  //
  const [nameValue, setNameValue] = useState(); // 1:海康，2：TP-LINK
  const [typeNum, setTypeNum] = useState(""); // 1:海康，2：TP-LINK
  //
  const [monitorStatus, setMonitorStatus] = useState(false); // 监控Modal状态
  //
  const [editValue, setEditValue] = useState({});

  //排序类型
  const [sortedInfo, setSortedInfo] = useState({});
  //
  const [isModalOpen, setIsModalOpen] = useState(false); //modal弹窗状态
  const [shopList, setShopList] = useState({}); // 门店列表数据（父级）
  const [loading, setLoading] = useState(false); // 门店列表loading
  const [account, setAccount] = useState(""); // 添加门店账号的value
  const [shopId, setShopId] = useState(""); // 门店id（--------用来做门店账号添加的----------）
  const [modaltext, setModaltext] = useState(""); // 动态添加/编辑Modal-title
  const [DeciverList, setDeciverList] = useState([]); // 产品线列表
  const [PullList, setPullList] = useState([]); // 门店下拉
  const [ShopListAuth, setShopListAuth] = useState([]); // 获取数据权限下的店内区域列表
  const [ShopListAuth1, setShopListAuth1] = useState([]); // 获取数据权限下的店内区域列表
  const [addressOptions, setAddressOptions] = useState([]); // 省市区树
  const [shopAuthList, setShopAuthList] = useState([]); // 门店权限列表
  const [districtAuthList, setDistrictAuthList] = useState([]); // 区域权限列表
  const [dataType, setDataType] = useState(undefined); // 数据类型（0：全部数据，1：按门店，2：按区域）
  // 预警值设置
  const [openStatus, setOpenStatus] = useState(false);
  const [warnType, setWarnType] = useState(undefined); // 识别预警类型：1：总识别率，2：首位识别率
  const [deviceWarn, setDeviceWarn] = useState(); // 设备预警值 (默认95)
  const [goodsWarn, setGoodsWarn] = useState(undefined); // 识别商品预警值
  const [modalLoading, setModalLoading] = useState(false); // 弹窗Loading
  const [NewDeviceWarn, setNewDeviceWarn] = useState(undefined);
  const [NewWarnType, setNewWarnType] = useState(undefined);
  //
  const [ParamsInfo, setParamsInfo] = useState({
    ProvinceCityRegion: [], //province省,city市,region区
    keyCode: "",
    pullValue: window.localStorage.getItem("shopId")
      ? Number(window.localStorage.getItem("shopId"))
      : "",
    productValue: [],
    shopId: "", //门店id
    scaleNo: "",
    online: "", //在线状态（0：离线,1：在线）
    code: "",
    pageNum: 1,
    pageSize: 20,
    orderType: undefined, // 排序传值
    regionId: undefined, // 店内区域ID
    changeType: undefined,
    changeTypeChild: undefined,
    warningQuery: JumpInfo ? JumpInfo : undefined, // 总识别率
  });
  let buttonPurview = [];
  if (localStorage.getItem("SanJiangEarlyWarning")) {
    buttonPurview = JSON.parse(localStorage.getItem("SanJiangEarlyWarning"));
  }

  const [remoteModal, setRemoteModal] = useState({ open: false }); //远程控制Modal
  const [remoteLoading, setRemoteLoading] = useState(false); //远程控制Modal
  const [fetchDataEnabled, setFetchDataEnabled] = useState(false);
  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  });

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    };
  }, [ParamsInfo]);
  useEffect(() => {
    !remoteModal.open && setRemoteLoading(false);
  }, [remoteModal]);

  useEffect(() => {
    getPullList(); //门店下拉列表
    getTreeList(); //产品线类型树
    getAreaTree(); // 省市区树
    getDataAuth(); // 当前项目的数据权限信息
    setTimeout(getShopList, 0);

    getSetWarning(); //获取项目的识别预警规则
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("INNERHEIGHT") < 700) {
      setWinHeight(sessionStorage.getItem(700));
    } else {
      setWinHeight(sessionStorage.getItem("INNERHEIGHT"));
    }
  }, [sessionStorage.getItem("INNERHEIGHT")]);
  const [count, setCount] = useState(0);

  /**
   * 当前项目的数据权限信息
   */
  const getDataAuth = async () => {
    try {
      const res = await DeviceServices.Data_Auth();
      if (!res) {
        return false;
      }
      if (res?.code != 200) {
        message.error(res?.msg);
        return;
      }

      setDataType(res?.data?.dataType == 0 ? "0" : res?.data?.dataType);
      setDistrictAuthList(res?.data?.districtAuthList);
      setShopAuthList(res?.data?.shopAuthList);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 查询
   */
  const handlSearch = () => {
    //点击查询时将子集关闭，排除数据污染
    setParamsInfo({ ...ParamsInfo, pageNum: 1 });
    setTimeout(getShopList, 0);
  };

  /**
   * 重置
   */
  const handClear = async () => {
    window.localStorage.removeItem("shopId");
    setParamsInfo({
      keyCode: "",
      pullValue: window.localStorage.getItem("shopId")
        ? Number(window.localStorage.getItem("shopId"))
        : "",
      ProvinceCityRegion: [], //province省,city市,region区
      productValue: [],
      shopId: "", //门店id
      scaleNo: "",
      online: "", //在线状态（0：离线,1：在线）
      code: "",
      pageNum: 1,
      pageSize: 20,
      orderType: undefined, // 排序传值
      regionId: undefined, // 店内区域ID
      changeType: undefined,
      changeTypeChild: undefined,
      warningQuery: undefined, // 总识别率
    });
    setSortedInfo("");
    setTimeout(getShopList, 0);
  };

  /**
   * 门店下拉列表
   */
  const getPullList = async () => {
    try {
      const params = { pageNum: 1, pageSize: 9999999 };
      const { code, data, msg } = await DeviceServices.Pull_list(params);
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data) {
        setPullList(data.list || []);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 获取数据权限下的店内区域列表
   */
  const getShopListUnderAuth = async (shopId) => {
    try {
      const params = { shopId };
      const { code, data, msg } = await DeviceServices.ShopListUnderAuth(
        params
      );
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data) {
        setShopListAuth(data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * 获取数据权限下的店内区域列表1
   */
  const getShopListUnderAuth1 = async (shopId) => {
    try {
      const params = { shopId };
      const { code, data, msg } = await DeviceServices.ShopListUnderAuth(
        params
      );
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data) {
        setShopListAuth1(data || []);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * 产品线类型树
   */
  const getTreeList = async () => {
    try {
      const { code, data, msg } = await DeviceServices.Tree_List();
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data.length > 0) {
        data.map((v) => {
          v.productLineTypeId = v.productLineId;
          v.productLineTypeName = v.productLineName;
        });
      }
      setDeciverList(data || []);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 省市区树
   */
  const getAreaTree = async () => {
    try {
      const { code, data, msg } = await DeviceServices.Area_Tree();
      if (code != 200) {
        message.error(msg);
        return;
      }
      setAddressOptions(data || []);
    } catch (error) {
      message.error(error);
      return;
    }
  };

  /**
   * 门店下设备列表
   */
  const getShopList = async () => {
    const { ParamsInfoRef } = refInfo?.current;
    const params = {
      ...ParamsInfoRef,
      province:
        ParamsInfoRef.ProvinceCityRegion.length > 0
          ? ParamsInfoRef.ProvinceCityRegion[0] || ""
          : "",
      city:
        ParamsInfoRef.ProvinceCityRegion.length > 0
          ? ParamsInfoRef.ProvinceCityRegion[1] || ""
          : "",
      region:
        ParamsInfoRef.ProvinceCityRegion.length > 0
          ? ParamsInfoRef.ProvinceCityRegion[2] || ""
          : "",
      productLineId:
        ParamsInfoRef.productValue.length > 0
          ? ParamsInfoRef.productValue[0] || ""
          : "",
      productLineTypeId:
        ParamsInfoRef.productValue.length > 0
          ? ParamsInfoRef.productValue[1] || ""
          : "",
    };
    try {
      setLoading(true);
      const { code, data, msg } = await DeviceServices.Devices_List(params);
      if (code != 200) {
        setLoading(false);
        message.error(msg);
        return;
      }
      setLoading(false);
      setShopList(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setShopList([]);
    }
  };

  // 打开modal
  const showModal = (info) => {
    const { shopId, shopAccount } = info;
    //判断，当shopAccount有值时为门店账号编辑，否则为添加
    if (shopAccount) {
      setModaltext("编辑");
      setAccount(shopAccount); //编辑时，数据回显
    } else {
      setModaltext("添加");
      setAccount(""); //添加门店账号时，保险起见先清除一次输入框
    }
    //存储数据的shopid
    setShopId(shopId || "");
    setIsModalOpen(true);
  };
  //modal确认按钮
  const handleOk = () => {
    if (account.length < 11) {
      message.error("请输入11位门店账号");
      return false;
    }
    const params = {
      account: account,
      shopId: shopId,
    };
    DeviceServices.Add_Account(params).then((res) => {
      if (res.code == 200 && res.data) {
        setShopId(""); //清除shopid
        setAccount(""); //清除门店账号
        setTimeout(getShopList, 0);
        setIsModalOpen(false);
        message.success(`门店账号${modaltext}成功`);
      }
    });
  };
  //关闭modal
  const handleCancel = () => {
    setShopId(""); //清除shopid
    setAccount(""); //清除门店账号
    setModaltext(""); //清除modal-title
    setIsModalOpen(false);
  };

  /**
   * 获取项目的识别预警规则
   */
  const getSetWarning = async () => {
    try {
      const res = await DeviceServices.SetWarning();
      if (!res) {
        return;
      }
      const { code, data, msg } = res;
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data) {
        setWarnType(data?.warningField || undefined);
        setDeviceWarn(
          data?.deviceWarning
            ? new Decimal(data?.deviceWarning).mul(100).toFixed(2)
            : data?.warningField == 1
            ? 95
            : 70 || data?.warningField == 1
            ? 95
            : 70
        );
        setNewWarnType(data?.warningField || undefined);
        setNewDeviceWarn(
          data?.deviceWarning
            ? new Decimal(data?.deviceWarning).mul(100).toFixed(2)
            : data?.warningField == 1
            ? 95
            : 70 || data?.warningField == 1
            ? 95
            : 70
        );
        setGoodsWarn(
          data?.goodsWarning
            ? new Decimal(data?.goodsWarning).mul(100).toFixed(2)
            : undefined || undefined
        );
      }
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 预警设置保存按钮
   */
  const onSave = async () => {
    try {
      const params = {
        warningField: warnType,
        deviceWarning:
          deviceWarn && deviceWarn > 0
            ? (deviceWarn / 100).toFixed(4)
            : undefined,
        goodsWarning:
          goodsWarn && goodsWarn > 0 ? (goodsWarn / 100).toFixed(4) : undefined,
      };
      if (!params.warningField) {
        return message.error("请选择预警类型");
      }
      if (!params.deviceWarning) {
        return message.error("请输入设备预警值");
      }
      console.log(params);
      setModalLoading(true);
      const res = await DeviceServices.SetWarningSave(params);
      if (!res) {
        setModalLoading(false);
        return;
      }
      const { code, data, msg } = res;
      if (code != 200) {
        message.error(msg);
        setModalLoading(false);
        return;
      }
      if (data) {
        message.success("预警设置成功");
        setModalLoading(false);
        setOpenStatus(false);
        setParamsInfo({ ...ParamsInfo, pageNum: 1 });
        setTimeout(getShopList, 0);
        getSetWarning();
      }
    } catch (error) {
      setModalLoading(false);
      console.log(error);
    }
  };

  /**
   * 编辑设备，打开Modal
   */
  const onHandOpenModal = (info) => {
    setEditDeviceOpen(true);
    getShopListUnderAuth(info.shopId || undefined);
    setFormValuie({
      ...FormValuie,
      shopId: info.shopId,
      regionId: info.regionId, // 所属区域
      scaleNo: info.scaleNo,
      id: info.deviceId,
    });
  };

  /**
   * 编辑设备，Modal确认按钮
   */
  const onSubmit = async () => {
    try {
      console.log(FormValuie);
      if (!FormValuie.scaleNo) {
        message.warning("请输入秤号");
        return;
      }
      if (!FormValuie.shopId) {
        message.warning("请选择门店");
        return;
      }
      const { code, msg, data } = await DeviceServices.Edit_Device(FormValuie);
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data) {
        message.success("设备编辑成功");
        setEditDeviceOpen(false);
        setFormValuie({
          scaleNo: undefined,
          id: undefined,
          shopId: undefined,
        });
        setTimeout(getShopList, 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 打开监控设置Modal
   */
  const onOpenModal = async () => {
    try {
      const { code, data, msg } = await DeviceServices.New_Monito();
      if (code != 200) {
        message.error(msg);
        return;
      }
      form.resetFields();
      setMonitorStatus(true);
      setNameValue(data.type); // 判断name1是海康还是name2TP-LINK
      setEditValue(data);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 监控设置Modal确认按钮
   */
  const onFinish = () => {
    form
      .validateFields()
      .then(async (values) => {
        if (editValue?.id) {
          values.id = editValue?.id;
          DeviceServices.Edit_Monito(values).then((res) => {
            if (res.code != 200) {
              return;
            }
            setMonitorStatus(false);
            if (res.data) {
              message.success("监控设置成功");
              //调用查询门店下的主账号接口
              setMonitorStatus(false);
            }
          });
        } else {
          DeviceServices.Add_Monitor(values).then((res) => {
            if (res.code != 200) {
              return;
            }
            setMonitorStatus(false);
            if (res.data) {
              message.success("监控设置成功");
              //调用查询门店下的主账号接口
              setMonitorStatus(false);
            }
          });
        }
      })
      .catch((err) => {
        // 校验失败
      });
  };

  /**
   * 导出数据
   */
  const onExport = async () => {
    try {
      const { ParamsInfoRef } = refInfo?.current;
      const params = {
        ...ParamsInfoRef,
        province:
          ParamsInfoRef.ProvinceCityRegion.length > 0
            ? ParamsInfoRef.ProvinceCityRegion[0] || ""
            : "",
        city:
          ParamsInfoRef.ProvinceCityRegion.length > 0
            ? ParamsInfoRef.ProvinceCityRegion[1] || ""
            : "",
        region:
          ParamsInfoRef.ProvinceCityRegion.length > 0
            ? ParamsInfoRef.ProvinceCityRegion[2] || ""
            : "",
        productLineId:
          ParamsInfoRef.productValue.length > 0
            ? ParamsInfoRef.productValue[0] || ""
            : "",
        productLineTypeId:
          ParamsInfoRef.productValue.length > 0
            ? ParamsInfoRef.productValue[1] || ""
            : "",
        ProvinceCityRegion: "",
        productValue: "",
      };
      const res = await DeviceServices.Export_Device(params);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    !remoteModal.open && setFetchDataEnabled(false);
  }, [remoteModal]);

  useEffect(() => {
    let intervalId = null;
    const onOpenRemoteResult = async () => {
      try {
        const { code, data, msg } = await DeviceServices.OpenRemoteResult({
          deviceId: remoteModal.deviceId,
        });
        if (code != 200) {
          message.error(msg);
          return;
        }

        const { errorMsg, ip, port, heartbeatUrl, remotePort } = data;
        if ((ip && port && heartbeatUrl, remotePort)) {
          window.localStorage.setItem("heartbeatUrl", heartbeatUrl);
          window.localStorage.setItem(
            "rfb",
            JSON.stringify({ ip, port, remotePort })
          );
          // window.open(`/vnc/vnc.html?host=${ip}&port=${port}`, "remoteWindow");
          if (windowRef.current && !windowRef.current.closed) {
            windowRef.current.location.href = `/vnc/vnc.html?host=${ip}&port=${port}`;
            windowRef.current.focus();
          }
          setRemoteLoading(false);
          setRemoteModal({ open: false });
          setCount(0);
          clearInterval(intervalId);
        } else if (count >= 18) {
          clearInterval(intervalId);
          // 关闭等待窗口
          if (windowRef.current && !windowRef.current.closed) {
            windowRef.current.close();
            windowRef.current = null;
          }
          confirm({
            title: "远程连接失败",
            icon: <CloseCircleFilled />,
            content: errorMsg || "连接请求超时，请检查网络后重试",
            okButtonProps: { style: { background: "#2eb998" } },
            okText: "重试",
            cancelText: "取消",
            onOk() {
              setCount(0);
              setFetchDataEnabled(true);
              onOpenRemote()
            },
            onCancel() {
              setCount(0);
              setFetchDataEnabled(false);
              setRemoteModal({ open: false });
              // 确保关闭窗口
              if (windowRef.current && !windowRef.current.closed) {
                windowRef.current.close();
                windowRef.current = null;
              }
            },
          });
        } else {
          setCount((prev) => prev + 1); // 未达到10次继续计数
        }
      } catch (error) {}
    };
    if (fetchDataEnabled) {
      intervalId = setInterval(onOpenRemoteResult, 3000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [count, fetchDataEnabled]);
  const onOpenRemote = async () => {
    setRemoteLoading(true);
    try {
      windowRef.current = window.open("", "remoteWindow");
      windowRef.current.document.write(`
        <html>
          <head>
            <title>正在连接...</title>
            <style>
              body { 
                background: #f0f2f5;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100vh;
                margin: 0;
              }
              .loading {
                text-align: center;
                padding: 20px;
                background: white;
                border-radius: 8px;
                box-shadow: 0 2px 8px rgba(0,0,0,0.1);
              }
              .spinner {
                border: 4px solid rgba(46, 185, 152, 0.2);
                border-top-color: #2EB998;
                border-radius: 50%;
                width: 40px;
                height: 40px;
                animation: spin 1s linear infinite;
                margin: 0 auto 15px;
              }
              @keyframes spin {
                to { transform: rotate(360deg); }
              }
            </style>
          </head>
          <body>
            <div class="loading">
              <div class="spinner"></div>
              <h3>正在连接远程设备，请稍候...</h3>
              <p>首次远程协助请到设备上点击“立即开始”授权</p>
            </div>
          </body>
        </html>
      `);
      const { code, msg, data } = await DeviceServices.OpenRemote({
        deviceId: remoteModal.deviceId,
      });

      if (data != null && !data) {
        windowRef.current.document.open();
        windowRef.current.document.write(`
          <html>
            <head>
              <title>连接失败</title>
              <style>
                body { 
                  background: #f0f2f5;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 100vh;
                  margin: 0;
                  visibility: hidden;
                }
                .error {
                  text-align: center;
                  padding: 20px;
                  background: white;
                  border-radius: 8px;
                  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
                }
                .icon {
                  width: 40px;
                  height: 40px;
                  margin: 0 auto 15px;
                  color: #ff4d4f;
                }
              </style>
            </head>
            <body>
              <div class="error">
                <svg class="icon" viewBox="0 0 1024 1024">
                  <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359c-1.2-1.5-1.9-3.3-1.9-5.2 0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z" fill="#ff4d4f"/>
                </svg>
                <h3 style="color: #ff4d4f;margin-bottom: 8px;">${
                  msg || "连接失败"
                }</h3>
                <p style="color: #999;">3秒钟后自动关闭</p>
              </div>
                <script>
                  (function(){
                      function init() {
                        document.body.style.visibility = 'visible';
                        setTimeout(() => window.close(), 3000);
                      }
                      document.readyState === 'complete' 
                        ? init() 
                        : window.addEventListener('load', init);
                    })();
                </script>
            </body>
          </html>
        `);

        windowRef.current.document.close();
        setRemoteLoading(false);
        return;
      }
      window.localStorage.setItem("deviceId", remoteModal.deviceId);
      setFetchDataEnabled(true);
    } catch (error) {
      // windowRef.current?.close();
      setRemoteLoading(false);
    }
  };

  //父级，门店列表接口
  const columns = [
    {
      title: "门店名称",
      dataIndex: "shopName",
      ellipsis: { showTitle: true },
      key: "shopName",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "门店编号",
      dataIndex: "shopCode",
      ellipsis: { showTitle: true },
      key: "shopCode",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "秤号",
      dataIndex: "scaleNo",
      ellipsis: { showTitle: true },
      key: "scaleNo",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "状态",
      dataIndex: "online",
      ellipsis: { showTitle: true },
      key: "online",
      width: 100,
      render: (info) => {
        return (
          <>
            {info == 0 ? (
              <div style={{ color: "red" }}>离线</div>
            ) : info == 1 ? (
              <div style={{ color: "#2EB998" }}>在线</div>
            ) : (
              "-"
            )}
          </>
        );
      },
    },
    {
      title: "称重笔数",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天的称重笔数">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "outputNum",
      ellipsis: { showTitle: true },
      key: "outputNum",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "outputNum" ? sortedInfo?.order : null,
      render: (info) => {
        return <>{!info || info == 0 ? "0" : info}</>;
      },
    },
    {
      title: "总识别率",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天的总识别率">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "aiOkRate",
      ellipsis: { showTitle: true },
      key: "aiOkRate",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder:
        sortedInfo?.columnKey === "aiOkRate" ? sortedInfo?.order : null,
      render: (info) => {
        return (
          <div>
            {!info || info == 0 ? (
              "0%"
            ) : (
              <span
                style={{
                  color:
                    info * 100 < NewDeviceWarn && NewWarnType == 1 ? "red" : "",
                }}
              >
                {(info * 100).toFixed(2) + "%"}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "首位识别率",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天的首位识别率">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "top1",
      ellipsis: { showTitle: true },
      key: "top1",
      width: 150,
      sorter: true,
      orderType: sortedInfo?.order,
      sortOrder: sortedInfo?.columnKey === "top1" ? sortedInfo?.order : null,
      render: (info) => {
        return (
          <div>
            {!info || info == 0 ? (
              "0%"
            ) : (
              <span
                style={{
                  color:
                    info * 100 < NewDeviceWarn && NewWarnType == 2 ? "red" : "",
                }}
              >
                {(info * 100).toFixed(2) + "%"}
              </span>
            )}
          </div>
        );
      },
    },
    {
      title: "搜索占比",
      filterDropdown: <div></div>,
      filterIcon: (
        <Tooltip placement="top" title="近7天的搜索占比">
          <QuestionCircleOutlined />
        </Tooltip>
      ),
      dataIndex: "optSearchRate",
      ellipsis: { showTitle: true },
      key: "optSearchRate",
      width: 150,
      render: (info) => {
        return <>{info > 0 ? (info * 100).toFixed(2) + " %" : "0%"}</>;
      },
    },
    {
      title: "设备激活码",
      dataIndex: "cdKeyCode",
      key: "cdKeyCode",
      width: 150,
      ellipsis: { showTitle: true },
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "设备IP地址",
      dataIndex: "ip",
      ellipsis: { showTitle: true },
      key: "ip",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "设备SN",
      dataIndex: "deviceSn",
      ellipsis: { showTitle: true },
      key: "deviceSn",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "产品线 - 类型",
      dataIndex: "productLineName",
      ellipsis: { showTitle: true },
      key: "productLineName",
      width: 150,
      render: (info, rowInfo) => {
        return (
          <>
            {rowInfo.productLineName} - {rowInfo.productLineTypeName}
          </>
        );
      },
    },
    {
      title: "版本号",
      dataIndex: "systemVersionName",
      ellipsis: { showTitle: true },
      key: "systemVersionName",
      width: 150,
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "地理位置",
      dataIndex: "address",
      ellipsis: { showTitle: true },
      key: "address",
      width: 200,
      render: (info) => {
        return (
          <div className={styles.device_table_info}>
            <Tooltip title={info} placement="topLeft">
              <span>{info ? info : "-"}</span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "使用区位",
      dataIndex: "regionName",
      ellipsis: { showTitle: true },
      key: "regionName",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "最近同步时间",
      dataIndex: "dataSyncDate",
      ellipsis: { showTitle: true },
      key: "dataSyncDate",
      width: 200,
      render: (info) => {
        return <>{info ? info : "-"}</>;
      },
    },
    {
      title: "操作",
      ellipsis: { showTitle: true },
      fixed: "right",
      render: (info, rowInfo) => {
        // console.log(info)
        return (
          <div className={styles.devicemanage_page_store_table_btn}>
            <a
              style={{
                color:
                  rowInfo?.branch == 2 || rowInfo?.branch == 3 ? "grey" : "",
              }}
              onClick={() => {
                if (rowInfo?.branch == 2 || rowInfo?.branch == 3) {
                  return;
                }
                history.push(
                  `/admin/devicemanage/lookGoods?deviceSn=${info?.deviceSn}$shopId=${info?.shopId}`
                );
              }}
            >
              查看商品
            </a>
            <a onClick={() => onHandOpenModal(rowInfo)}>编辑设备</a>

            <Dropdown
              arrow
              placement="bottomRight"
              menu={{
                items: [
                  {
                    key: "1",
                    label: (
                      <Button
                        color="default"
                        variant="link"
                        size="small"
                        onClick={() => {
                          history.push(
                            `/admin/devicemanage/bindingCamera?deviceSn=${info?.deviceSn}$cdKeyCode=${info?.cdKeyCode}$scaleNo=${info?.scaleNo}`
                          );
                        }}
                      >
                        绑定监控设备
                      </Button>
                    ),
                  },
                  // 仅在存在远程控制权限时显示
                  ...(buttonPurview.some(
                    (item) =>
                      item.router === "/admin/devicemanage/remoteControl"
                  )
                    ? [
                        {
                          key: "2",
                          label: (
                            <Button
                              color="default"
                              variant="link"
                              size="small"
                              disabled={rowInfo?.remoteControlType !== 1}
                              onClick={() =>
                                setRemoteModal({
                                  open: true,
                                  deviceId: rowInfo.deviceId,
                                  isAndroid7:
                                    rowInfo?.operatingSystemBigVersion === "7",
                                })
                              }
                            >
                              远程控制
                            </Button>
                          ),
                        },
                      ]
                    : []),
                ],
              }}
            >
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  更多
                  <DownOutlined />
                </Space>
              </a>
            </Dropdown>
            {/* <a
              type="link"
              style={{ color: "#19BC89" }}
              onClick={() => onHandOpenModal(info)}
            >
              编辑设备
            </a> */}
          </div>
        );
      },
    },
  ];

  /**
   * 排序
   */
  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);

    // ascend:正序      descend：倒序
    if (sorter.field === "outputNum") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 2 : sorter.order === "descend" ? 1 : "",
        pageNum: pagination.current,
      });
    }
    if (sorter.field === "aiOkRate") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 3 : sorter.order === "descend" ? 4 : "",
        pageNum: pagination.current,
      });
    }
    if (sorter.field === "top1") {
      setParamsInfo({
        ...ParamsInfo,
        orderType:
          sorter.order === "ascend" ? 6 : sorter.order === "descend" ? 5 : "",
        pageNum: pagination.current,
      });
    }
    if (!sorter.field) {
      setParamsInfo({
        ...ParamsInfo,
        pageNum: pagination.current,
      });
    }

    setTimeout(getShopList, 0);
  };
  //分页
  const onTableChange = () => {
    const paginationProps = {
      showSizeChanger: false,
      showQuickJumper: false,
      showTotal: () => `共${shopList?.total}条`,
      pageSize: ParamsInfo.pageSize,
      current: ParamsInfo.pageNum,
      total: shopList?.total,
    };
    return paginationProps;
  };
  return (
    <div>
      <Card>
        <div className={styles.device_list_head}>
          <div className={styles.device_list_head_search}>
            {dataType == 2 ? (
              <div className={styles.device_list_head_search_input_or_select}>
                区域选择：
                <Select
                  allowClear
                  style={{ width: 200 }}
                  value={ParamsInfo?.changeTypeChild || undefined}
                  placeholder="请选择区域名称"
                  onChange={(e) => {
                    setParamsInfo({ ...ParamsInfo, changeTypeChild: e });
                  }}
                >
                  {districtAuthList &&
                    districtAuthList.map((v) => {
                      return (
                        <Option value={v.districtId} key={v.districtId}>
                          {v.pathName && v.districtName
                            ? v.pathName + "/" + v.districtName
                            : !v.pathName && v.districtName
                            ? v.districtName
                            : v.pathName && !v.districtName
                            ? v.pathName
                            : ""}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            ) : null}

            <div className={styles.device_list_head_search_input_or_select}>
              门店名称：
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                style={{ width: 200 }}
                placeholder="请选择所属门店"
                allowClear
                value={ParamsInfo.shopId || undefined}
                onChange={(e) => {
                  if (!e) {
                    setParamsInfo({
                      ...ParamsInfo,
                      shopId: e || undefined,
                      regionId: undefined,
                    });
                    setShopListAuth1([]);
                    return;
                  }
                  //清除门店管理点击设备数跳转设备管理的门店id
                  window.localStorage.removeItem("shopId");
                  setParamsInfo({
                    ...ParamsInfo,
                    shopId: e || undefined,
                    regionId: undefined,
                  });
                  getShopListUnderAuth1(e);
                }}
              >
                {PullList.map((v) => {
                  return (
                    <Option value={v.shopId} key={v.shopId}>
                      {v.shopName}
                    </Option>
                  );
                })}
              </Select>
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              使用区位：
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属区位"
                style={{ width: 200 }}
                allowClear
                value={ParamsInfo?.regionId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setParamsInfo({ ...ParamsInfo, regionId: e || undefined });
                }}
              >
                {ShopListAuth1 &&
                  ShopListAuth1.map((item, index) => {
                    return (
                      <Option value={item?.id} key={item?.id}>
                        {item?.name}
                      </Option>
                    );
                  })}
              </Select>
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              地理位置：
              <Cascader
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择地理位置"
                style={{ width: 200 }}
                fieldNames={{
                  label: "name",
                  value: "name",
                  children: "areaListRspList",
                }}
                options={addressOptions}
                value={ParamsInfo.ProvinceCityRegion}
                allowClear
                changeOnSelect
                onChange={(e) =>
                  setParamsInfo({ ...ParamsInfo, ProvinceCityRegion: e || "" })
                }
              />
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              秤号/设备SN/激活码：
              <PubInput
                onChange={(val) => setParamsInfo({ ...ParamsInfo, code: val })}
                width="220px"
                placeholder="秤号/设备SN/激活码"
                value={ParamsInfo.code}
              />
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              产品线：
              <Cascader
                showSearch
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择产品线/类型"
                style={{ width: 200 }}
                fieldNames={{
                  label: "productLineTypeName",
                  value: "productLineTypeId",
                  children: "productLineTypeList",
                }}
                options={DeciverList}
                value={ParamsInfo.productValue || []}
                allowClear
                changeOnSelect
                onChange={(e) =>
                  setParamsInfo({ ...ParamsInfo, productValue: e || "" })
                }
              />
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              状态：
              <Select
                placeholder="请选择状态"
                style={{ width: 200 }}
                value={ParamsInfo.online || undefined}
                allowClear
                onChange={(e) => {
                  if (e) {
                    setParamsInfo({ ...ParamsInfo, online: `${e}` || "" });
                  } else {
                    setParamsInfo({ ...ParamsInfo, online: undefined });
                  }
                }}
                options={[
                  { value: "1", label: "在线" },
                  { value: "0", label: "离线" },
                ]}
              />
            </div>

            <div className={styles.device_list_head_search_input_or_select}>
              {warnType == 1 ? "总识别率：" : "首位识别率："}
              <Select
                placeholder="请选择"
                style={{ width: 200 }}
                value={ParamsInfo.warningQuery || undefined}
                allowClear
                onChange={(e) => {
                  setParamsInfo({ ...ParamsInfo, warningQuery: e });
                }}
                options={[
                  { value: "1", label: "高于预警值" },
                  { value: "2", label: "低于预警值" },
                ]}
              />
            </div>
          </div>
          <div className={styles.device_list_head_right}>
            <Button type="primary" onClick={handlSearch}>
              查询
            </Button>
            <Button onClick={handClear}>重置</Button>
          </div>
        </div>
      </Card>

      <Card style={{ marginTop: "16px" }}>
        {/* 导出，监控设置 */}
        <div className={styles.device_list_caozo_btn}>
          {/* <Button type="primary" icon={<ToTopOutlined />}>
          导出
        </Button> */}
          {/* <Button type="primary" onClick={() => onOpenModal()}>
            监控设置
          </Button> */}
          <Button type="primary" onClick={onExport}>
            导出数据
          </Button>
          <Button
            style={{ borderColor: "#2eb998", color: "#2eb998" }}
            onClick={() => {
              //获取项目的识别预警规则
              getSetWarning();
              setModalLoading(false);
              setOpenStatus(true);
            }}
          >
            预警设置
          </Button>
        </div>
        <div>
          <Table
            columns={columns}
            pagination={onTableChange()}
            dataSource={shopList?.list ? shopList?.list : []}
            rowKey="deviceId"
            loading={loading}
            scroll={{ x: "max-content", y: `${WinHeight - 470}px` }}
            onChange={handleChange}
            // style={{ minHeight: `${WinHeight - 350}px` }}
          />
        </div>
      </Card>
      {/* 监控设置 */}

      <Modal
        title="监控设置"
        open={monitorStatus}
        onOk={onFinish}
        destroyOnClose
        onCancel={() => {
          setMonitorStatus(false);
        }}
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 10 }}
          onFinish={onFinish}
        >
          <Form.Item
            label="摄像头品牌"
            name="type"
            initialValue={editValue?.type || undefined}
            rules={[
              {
                required: true,
                message: "摄像头品牌不能为空!",
              },
            ]}
          >
            <Select
              width={200}
              allowClear
              placeholder="请选择摄像头品牌！"
              onChange={(e) => {
                setNameValue(e);
              }}
            >
              {/* <Option key={1} value={1}>
                海康
              </Option> */}
              <Option key={2} value={2}>
                TP-LINK
              </Option>
            </Select>
          </Form.Item>

          {nameValue == 1 ? (
            <Form.Item
              label="IP"
              name="ip"
              initialValue={editValue?.ip || ""}
              rules={[
                {
                  required: true,
                  message: "IP不能为空!",
                },
              ]}
            >
              <PubInput placeholder="IP！" max={15} />
            </Form.Item>
          ) : null}
          {nameValue == 1 ? (
            <Form.Item
              label="端口"
              name="port"
              initialValue={editValue?.port || ""}
              rules={[
                {
                  required: true,
                  message: "端口不能为空!",
                },
              ]}
            >
              <PubInput placeholder="端口！" max={15} />
            </Form.Item>
          ) : null}

          {nameValue == 2 ? (
            <Form.Item
              label="客户端ID"
              name="terminalId"
              initialValue={editValue?.terminalId || ""}
              rules={[
                {
                  required: true,
                  message: "客户端ID不能为空!",
                },
              ]}
            >
              <PubInput placeholder="客户端ID！" max={30} />
            </Form.Item>
          ) : null}

          <Form.Item
            label="appKey"
            name="appKey"
            initialValue={editValue?.appKey || ""}
            rules={[
              {
                required: true,
                message: "appKey不能为空!",
              },
            ]}
          >
            <PubInput placeholder="appKey！" max={50} />
          </Form.Item>
          <Form.Item
            label="secret"
            name="secret"
            initialValue={editValue?.secret || ""}
            rules={[
              {
                required: true,
                message: "secret不能为空!",
              },
            ]}
          >
            <PubInput placeholder="secret！" max={50} />
          </Form.Item>
        </Form>
      </Modal>

      {/* 编辑设备 */}
      <Modal
        title="编辑设备"
        open={EditDeviceOpen}
        keyboard
        maskClosable
        onOk={() => onSubmit()}
        onCancel={() => {
          setEditDeviceOpen(false);
        }}
      >
        <div className={styles.device_list_modal_edit_device}>
          <div className={styles.device_list_modal_edit_device_child}>
            <div className={styles.device_list_modal_edit_device_childname}>
              秤号： <span>*</span>
            </div>
            <PubInput
              onChange={(val) => setFormValuie({ ...FormValuie, scaleNo: val })}
              placeholder="秤号"
              value={FormValuie?.scaleNo}
              max={20}
              showCount={true}
            />
          </div>
          <div className={styles.page_store_select}>
            <div className={styles.device_list_modal_edit_device_child}>
              <div className={styles.device_list_modal_edit_device_childname}>
                所属门店： <span>*</span>
              </div>
              <Select
                // mode="multiple"
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="请选择所属门店"
                style={{ width: 200 }}
                allowClear
                value={FormValuie?.shopId}
                showSearch
                filterOption={(i, o) => o.props.children.includes(i)}
                onChange={(e) => {
                  setFormValuie({
                    ...FormValuie,
                    shopId: e,
                    regionId: undefined,
                  });
                  getShopListUnderAuth(e || undefined);
                }}
              >
                {PullList &&
                  PullList.map((item, index) => {
                    return (
                      <Option value={item?.shopId} key={index}>
                        {item?.shopName}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          </div>
          <div className={styles.device_list_modal_edit_device_child}>
            <div className={styles.device_list_modal_edit_device_childname}>
              所属区位：
            </div>
            <Select
              // mode="multiple"
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              placeholder="请选择所属区位"
              style={{ width: 200 }}
              allowClear
              value={FormValuie?.regionId}
              showSearch
              filterOption={(i, o) => o.props.children.includes(i)}
              onChange={(e) => {
                setFormValuie({ ...FormValuie, regionId: e });
              }}
            >
              {ShopListAuth &&
                ShopListAuth.map((item, index) => {
                  return (
                    <Option value={item?.id} key={item?.id}>
                      {item?.name}
                    </Option>
                  );
                })}
            </Select>
          </div>
        </div>
      </Modal>

      {/* 预警设置 */}
      <Modal
        title="预警设置"
        keyboard
        maskClosable
        open={openStatus}
        onCancel={() => {
          setOpenStatus(false);
          //获取项目的识别预警规则
          getSetWarning();
        }}
        footer={[
          <Button
            onClick={() => {
              setOpenStatus(false);
              //获取项目的识别预警规则
              getSetWarning();
            }}
          >
            取消
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onSave}
            loading={modalLoading}
          >
            保存
          </Button>,
        ]}
      >
        <div>
          <div className={styles.pageModalHead}>
            <div style={{ padding: "10px" }}>
              设置后，若设备或商品近7天的平均识别率低于预警值，则将生成对应的统计报告
            </div>
          </div>
          <div className={styles.pageModalBody}>
            <div className={styles.pageModalBoody}>
              <div className={styles.pageModalBoodyTitle}>识别预警类型：</div>
              <Select
                style={{ width: 200 }}
                value={warnType}
                onChange={(e) => {
                  setWarnType(e);
                  if (e == 1 && e != NewWarnType) {
                    setDeviceWarn(95);
                  }
                  if (e == 2 && e != NewWarnType) {
                    setDeviceWarn(70);
                  }
                  if (e == NewWarnType) {
                    setDeviceWarn(NewDeviceWarn);
                  }
                }}
                placeholder="请选择"
              >
                <Option key={1} value={1}>
                  总识别率
                </Option>
                <Option key={2} value={2}>
                  首位识别率
                </Option>
              </Select>
            </div>

            <div className={styles.pageModalBoody}>
              <div className={styles.pageModalBoodyTitle}>
                <span style={{ color: "#F56C6C", marginRight: "3px" }}>*</span>
                设备预警值：
              </div>
              <Input
                style={{ width: 200 }}
                placeholder="请输入设备预警值"
                addonAfter="%"
                value={deviceWarn}
                onChange={(e) => {
                  let value = e.target.value;

                  // 去除非数字、小数点以外的字符
                  value = value.replace(/[^\d.]/g, "");

                  // 限制只能有一个小数点
                  if (value.split(".").length > 2) {
                    value = value.slice(0, -1);
                  }

                  // 如果是小数，确保保留两位小数
                  if (value.indexOf(".") !== -1) {
                    const [integerPart, decimalPart] = value.split(".");
                    value = `${integerPart}.${decimalPart.slice(0, 2)}`;
                  }

                  // 限制不能大于100
                  if (value && parseFloat(value) > 100) {
                    value = "100";
                  }

                  setDeviceWarn(value);
                }}
              />
            </div>

            <div className={styles.pageModalBoody}>
              <div className={styles.pageModalBoodyTitle}>商品预警值：</div>
              <Input
                style={{ width: 200 }}
                placeholder="请输入商品预警值"
                addonAfter="%"
                value={goodsWarn}
                onChange={(e) => {
                  e.target.value.replace(/[^\d]/g, "");
                  {
                    let value = e.target.value;

                    // 去除非数字、小数点以外的字符
                    value = value.replace(/[^\d.]/g, "");

                    // 限制只能有一个小数点
                    if (value.split(".").length > 2) {
                      value = value.slice(0, -1);
                    }

                    // 如果是小数，确保保留两位小数
                    if (value.indexOf(".") !== -1) {
                      const [integerPart, decimalPart] = value.split(".");
                      value = `${integerPart}.${decimalPart.slice(0, 2)}`;
                    }

                    // 限制不能大于100
                    if (value && parseFloat(value) > 100) {
                      value = "100";
                    }

                    setGoodsWarn(value);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        title="系统提示"
        open={remoteModal.open}
        onCancel={() => setRemoteModal({ ...remoteModal, open: false })}
        maskClosable={false}
        footer={[
          <Button
            onClick={() => setRemoteModal({ ...remoteModal, open: false })}
          >
            取消
          </Button>,
          <Button
            type="primary"
            onClick={onOpenRemote}
            loading={remoteLoading}
            iconPosition="end"
          >
            确定
          </Button>,
        ]}
      >
        <div>
          <QuestionCircleFilled style={{ color: "#ff7d01" }} />
          <span style={{ marginLeft: "8px" }}>首次远程协助请到设备上点击“立即开始”授权，请确认是否对该设备进行远程？</span>
        </div>
      </Modal>
    </div>
  );
}

export default Index;
