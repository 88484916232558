import { POST, GET } from "../../utils/request";
import { stringify } from "qs";
import { newRequest } from "../../utils/newRequest";

/**
 * 门店列表接口
 */
export const Shop_List = (params) =>
  // GET(`/data/center/v1/shop/list?${stringify(params)}`)
  POST("/data/center/v1/shop/list", params);

/**
 * 门店下设备下拉列表
 */
export const Shop_Device = (params) =>
  GET(`/data/center/v1/device/pull/list?shopId=${params}`);

/**
 * 添加新的防加塞规则
 */
export const Review_Add = (params) =>
  POST("/data/center/v1/setting/we1ght/review/add", params);

/**
 * 编辑防加塞规则
 */
export const Review_Edit = (params) =>
  POST("/data/center/v1/setting/weight/review/edit", params);

/**
 * 防加塞规则详情
 */
export const Review_Detail = (id) =>
  GET(`/data/center/v1/setting/weight/review/detail?id=${id}`);

/**
 * 防加塞规则删除
 */
export const Review_Delete = (id) =>
  GET(`/data/center/v1/setting/weight/review/delete?id=${id}`);

/**
 * 防加塞规则列表
 */
export const Review_List = (params) =>
  GET(`/data/center/v1/setting/weight/review/list?${stringify(params)}`);

/**
 * 防加塞规则开启关闭
 */
export const Review_Switch = (params) =>
  POST("/data/center/v1/setting/weight/review/switch", params);

/**
 * 获取门店下商品信息
 */
export const Shop_Sku_List = (params) =>
  GET(`/data/center/v1/goods/shop/sku/list?${stringify(params)}`);

/**
 * 复核记录返回列表
 */
export const Weight_Records_List = (params) =>
  GET(`/data/center/v1/goods/review/weight/records/list?${stringify(params)}`);

/**
 * 复核记录列表导出
 */
export async function ExpotRecords(params) {
  return newRequest(
    `/data/center/v1/goods/review/weight/records/derivedlist?${stringify(
      params
    )}`,
    {
      responseType: "blob",
    }
  );
}
