import React, { useEffect, useState, useRef } from "react";
import { Button, Card, message, Select, Checkbox, Input } from "antd";
import * as OrphanServer from "../../../services/orphan";
import PubInput from "../../../../components/PubInput";
import styles from "./Index.module.css";

const { Option } = Select;

function Index(props) {
  const [id] = useState(props.location.search.split("=")[1]);
  const [shopList, setShopList] = useState([]); // 门店列表
  const [goodsList, setGoodsList] = useState([]); // 商品列表
  const [devicelist, setDevicelist] = useState([]); // 设备列表
  const [goodsRule, setGoodsRule] = useState([]); // 商品图片库icon列表
  const [ParamsInfo, setParamsInfo] = useState({
    name: undefined, // 规则名称
    shopId: undefined, // 适用门店
    skuList: [], // 商品plu
    timeoutMinute: "", // 超时时间
    isPrePack: false,
    isDistinct: false,
    deviceSnList: [], // 设备列表
  });

  // REF,动态值，影响表格接口刷新
  const refInfo = useRef({
    ParamsInfoRef: {}, //点击查询后的条件值
  });

  useEffect(() => {
    refInfo.current = {
      ParamsInfoRef: ParamsInfo,
    };
  }, [ParamsInfo]);

  /**
   * 初始化
   */
  useEffect(() => {
    //详情接口
    getDetailInfo();
    // 门店列表
    getShopList();
    //请求商品图片库icon列表接口
    getIconList();
  }, []);

  /**
   * 获取编辑详情
   */
  const getDetailInfo = async () => {
    try {
      const { code, msg, data } = await OrphanServer.orphan_good_detail(id);
      if (code != 200) {
        message.error(msg);
        return;
      }
      setParamsInfo({
        name: data.name || "", // 规则名称
        shopId: data.shopId || undefined, // 适用门店
        skuList: data.skuList, // 商品plu
        timeoutMinute: data.timeoutMinute, // 超时时间
        isPrePack:
          data.isPrePack === 2 ? true : data.isPrePack === 1 ? false : false,
        isDistinct:
          data.isDistinct === 2 ? true : data.isDistinct === 1 ? false : false,
        deviceSnList: data.deviceSnList || [],
      });
      // 请求该门店下的商品列表s
      getShopSkuList(data.shopId);
      // 请求该门店下的设备列表
      getDeviceList(data.shopId);
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * 获取门店列表
   */
  const getShopList = async (index) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        code: "",
      };
      const { code, msg, data } = await OrphanServer.Shop_List(params);
      if (code != 200) {
        message.error(msg);
        return;
      }
      setShopList(data?.list || []);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 获取设备列表默认
   */
  const getDeviceList = async (shopId) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 1000,
        keyCode: "",
        productLineId: "",
        productLineTypeId: "",
        shopId,
        scaleNo: "",
        keyCodeOrScaleNo: "",
        deviceSnList: [],
      };
      const { code, msg, data } = await OrphanServer.Devices_ListOne(params);
      if (code !== 200) {
        message.error(msg);
        return;
      }
      if (data && data?.list.length > 0) {
        data?.list.map((v) => {
          v.showInfo = v?.cdKeyCode + (v?.scaleNo ? " - " + v?.scaleNo : "");
        });
        setDevicelist(data?.list);
      } else {
        setDevicelist([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 获取门店下商品信息
   */
  const getShopSkuList = async (shopId) => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 999999,
        shopId: shopId,
        priceUnit: "0",
        skuName: "",
      };
      const { code, msg, data } = await OrphanServer.Shop_Sku_List(params);
      if (code != 200) {
        message.error(msg);
        return;
      }
      setGoodsList(data?.list || []);
    } catch (error) {}
  };
  /**
   * 商品图片库icon列表接口
   */
  const getIconList = async () => {
    try {
      const params = {
        pageNum: 1,
        pageSize: 500,
      };
      const { code, msg, data } = await OrphanServer.IconList(params);
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data && data?.list.length > 0) {
        data.list.map((v) => {
          v.showTitle = v?.skuCode + "-" + v.skuName;
        });
        setGoodsRule(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };
  /**
   * 商品图片库icon列表接口 查询
   */
  const getIconListSearch = async (value) => {
    try {
      const params = {
        code: value,
        pageNum: 1,
        pageSize: 500,
      };
      const { code, msg, data } = await OrphanServer.IconList(params);
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data && data?.list.length > 0) {
        let NewArr = goodsRule;
        var obj = {};
        var newarr = [];
        NewArr = data?.list.concat(NewArr);
        for (var i = 0; i < NewArr.length; i++) {
          NewArr[i].showTitle = NewArr[i]?.skuCode + "-" + NewArr[i]?.skuName;
          if (!obj[NewArr[i].skuCode] && !obj[NewArr[i].skuName]) {
            newarr.push(NewArr[i]);
            obj[NewArr[i].skuCode] = true;
          }
        }
        setGoodsRule(newarr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 保存
   */
  const onSaveSet = async () => {
    try {
      ParamsInfo.isOpen = 0;
      if (!ParamsInfo?.name) {
        message.warning("请输入规则名称");
        return;
      }
      if (!ParamsInfo?.shopId) {
        message.warning("请选择适用门店");
        return;
      }
      if (!ParamsInfo?.skuList || ParamsInfo?.skuList.length == 0) {
        message.warning("请选择商品plu");
        return;
      }
      // if (!ParamsInfo?.isPrePack) {
      //   message.warning("请勾选不监控商品预包装的标签")
      //   return
      // }
      if (!ParamsInfo?.timeoutMinute) {
        message.warning("请输入超时时间");
        return;
      }
      if (ParamsInfo?.timeoutMinute < 5) {
        message.warning("设置超时时间不能小于5分钟");
        return;
      }
      let params = ParamsInfo;
      // if (ParamsInfo.shopIdList[0] == 0) {
      //   params = {
      //     ...ParamsInfo,
      //     shopIdList: [],
      //   }
      // }
      params.id = id;
      params.isPrePack = params.isPrePack ? 2 : 1;
      params.isDistinct = params.isDistinct ? 2 : 1;
      const { code, data, msg } = await OrphanServer.orphan_good_edit(params);
      if (code != 200) {
        message.error(msg);
        return;
      }
      if (data) {
        message.success("编辑成功");
        window.history.back(-1);
      }
    } catch (error) {
      console.log(error);
      // message.error("保存失败")
    }
  };

  // 新建称重规则
  return (
    <Card>
      <div className={styles.add_loss_body}>
        {/* 规则名称 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          规则名称：
          <PubInput
            onChange={(val) => {
              setParamsInfo({
                ...ParamsInfo,
                name: val,
              });
            }}
            placeholder="规则名称"
            value={ParamsInfo.name}
            width={500}
            showCount={true}
            max={20}
          />
        </div>

        {/* 适用门店 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          适用门店：
          <Select
            // mode="multiple"
            disabled={true}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder="请选择适用门店"
            style={{ width: 500 }}
            allowClear
            value={ParamsInfo.shopId}
            showSearch
            filterOption={(i, o) => o.props.children.includes(i)}
            onChange={(e) => {
              if (e) {
                setGoodsList([]);
                setDevicelist([]);
                // 请求该门店下的商品列表
                getShopSkuList(e);
                // 请求该门店下的设备列表
                getDeviceList(e);
              } else {
                setGoodsList([]);
                setDevicelist([]);
              }
              setParamsInfo({
                ...ParamsInfo,
                shopId: e,
                skuList: [],
                deviceSnList: [],
              });
            }}
          >
            {shopList.map((item, index) => {
              return (
                <Option value={item?.shopId} key={index}>
                  {item?.shopName}
                </Option>
              );
            })}
          </Select>
        </div>

        {/* 商品plu： */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          商品plu ：
          <Select
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder="请选择商品plu"
            style={{ width: 500 }}
            allowClear
            value={ParamsInfo.skuList}
            showSearch
            filterOption={(i, o) =>
              (o.props.children[2] + "" + o.props.children[0]).includes(i)
            }
            onChange={(e) => {
              setParamsInfo({
                ...ParamsInfo,
                skuList: e,
              });
            }}
          >
            {goodsList &&
              goodsList.map((item, index) => {
                return (
                  <Option value={item?.skuCode} key={index}>
                    {item?.skuCode} {item?.skuName}
                  </Option>
                );
              })}
          </Select>
        </div>

        {/* 不生效设备： */}
        <div className={styles.add_loss_body_child}>
          <span style={{ marginLeft: "10px" }}>不生效设备 ：</span>
          <Select
            maxTagCount={4}
            mode="multiple"
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
            placeholder="请选择不生效设备"
            style={{ width: 500 }}
            allowClear
            value={ParamsInfo.deviceSnList}
            showSearch
            filterOption={(i, o) => o.props.children.includes(i)}
            onChange={(e) => {
              setParamsInfo({
                ...ParamsInfo,
                deviceSnList: e || [],
              });
            }}
          >
            {devicelist.map((item, index) => {
              return (
                <Option value={item?.deviceSn} key={index}>
                  {item?.showInfo}
                </Option>
              );
            })}
          </Select>
        </div>

        {/* 不监控商品预包装的标签 */}
        <div
          className={styles.add_loss_body_child}
          style={{ marginLeft: "10px" }}
        >
          {/* <span className={styles.add_loss_body_child_span}>*</span> */}
          <Checkbox
            onChange={(e) => {
              setParamsInfo({
                ...ParamsInfo,
                isPrePack: e.target.checked,
              });
            }}
            checked={ParamsInfo.isPrePack}
          >
            不监控商品预包装的标签
          </Checkbox>
        </div>

        {/* 是否监控同一商品 */}
        <div
          className={styles.add_loss_body_child}
          style={{ marginLeft: "10px" }}
        >
          {/* <span className={styles.add_loss_body_child_span}>*</span> */}
          <Checkbox
            onChange={(e) => {
              setParamsInfo({
                ...ParamsInfo,
                isDistinct: e.target.checked,
              });
            }}
            checked={ParamsInfo.isDistinct}
          >
            监控同一商品，相同重量且连续出纸只报出一张
          </Checkbox>
        </div>

        {/* 超时时间 */}
        <div className={styles.add_loss_body_child}>
          <span className={styles.add_loss_body_child_span}>*</span>
          超时时间：
          <div className={styles.add_loss_body_child_input}>
            打标签超时
            <Input
              addonAfter="分"
              placeholder="请设置"
              style={{ width: "150px", padding: "0 10px" }}
              value={ParamsInfo.timeoutMinute}
              onChange={(e) => {
                // 使用正则表达式移除非数字字符
                let newValue = e.target.value.replace(/[^0-9]/g, "");
                setParamsInfo({
                  ...ParamsInfo,
                  timeoutMinute: newValue,
                });
              }}
              // onBlur={(e) => {
              //   let NewStr = e.target.value || ""
              //   if (NewStr && NewStr < 30) {
              //     setParamsInfo({
              //       ...ParamsInfo,
              //       timeoutMinute: "",
              //     })
              //     message.warning("设置超时时间”不能小于30分钟")
              //   }
              // }}
            />
            未收银，视为丢失商品
          </div>
        </div>

        {/* 确定取消 */}
        <div className={styles.add_loss_body_child}>
          <Button type="primary" onClick={onSaveSet}>
            确定
          </Button>
          <Button
            style={{ marginLeft: "20px" }}
            onClick={() => {
              window.history.back(-1);
            }}
          >
            取消
          </Button>
        </div>
        {/* </Card> */}
      </div>
    </Card>
  );
}

export default Index;
